export function formatMinutesToHoursAndMinutes(totalMinutes: number): string {
    const roundedMinutes = Math.round(totalMinutes); // Arredonda os minutos para evitar valores decimais
    const hours = Math.floor(roundedMinutes / 60); // Calcula as horas inteiras
    const minutes = roundedMinutes % 60; // Calcula os minutos restantes

    const hoursText = hours > 0 ? `${hours}h` : ""; // Adiciona "h" apenas se houver horas
    const minutesText = minutes > 0 ? `${minutes}min` : ""; // Adiciona "min" apenas se houver minutos

    // Junta as partes, separando por espaço, e remove espaços extras
    return `${hoursText} ${minutesText}`.trim();
}
