import React from "react";
import { Button, CheckPicker, DatePicker, DateRangePicker, Icon, Input, List, Modal, Panel, SelectPicker } from "rsuite";
import ApexCharts from 'apexcharts'
import ReactApexChart from "react-apexcharts";
import axios from "../../../utilities/axios";
import dayjs from "dayjs";
import { locale } from "../../../utilities/locale/DateRangePicker";

export class StateOpenClosedReport extends React.Component<any, {}> {
    public state: any = {
        total: 0,
        series: [
            {
                name: 'Abertos',
                data: []
            },
            {
                name: 'Finalizados',
                data: []
            }
        ],
        options: {
            chart: {
                width: 400,
                type: 'bar',
                events: {
                    dataPointSelection: async (event: any, chartContext: any, config: any) => {
                        const seriesIndex = config.seriesIndex;
                        const { modalData } = this.state;

                        let selectedModalData: any = [];

                        if (seriesIndex === 0) {
                            selectedModalData = modalData.map((item: any) => {
                                if (item.projectSituation === 2) {
                                    return item
                                }
                            })

                            this.setState(({
                                modalTitle: "Projetos Abertos"
                            }))
                        }

                        if (seriesIndex === 1) {
                            selectedModalData = modalData.map((item: any) => {
                                if (item.projectSituation === 5) {
                                    return item
                                }
                            })

                            this.setState(({
                                modalTitle: "Projetos Finalizados"
                            }))
                        }

                        selectedModalData = selectedModalData.filter((item: any) => item !== undefined)

                        this.setState({
                            isModalOpen: true,
                            selectedModalData: Array.isArray(selectedModalData)
                                ? selectedModalData
                                : [selectedModalData]
                        });
                    }

                }
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
        filter: {
            status: [],
            date: [dayjs().startOf('month'), dayjs().endOf('month')]
        },
        isLoading: false,
        isModalOpen: false,
        modalData: [],
        selectedModalData: [],
        modalTitle: ""
    }

    componentDidMount(): void {
        this.onSubmitButton()
    }

    onCloseModal = () => {
        this.setState({
            isModalOpen: false
        })
    }

    onSubmitButton = () => {
        this.setState({ isLoading: true })
        axios.get("/api/v1/projects/reports/stateopenclosedprojects", {
            params: {
                filter: this.state.filter
            }
        }).then((res) => {
            if (res?.status == 200) {
                this.setState({
                    series: res.data?.series,
                    total: res.data?.projectCount,
                    options: {
                        ...this.state.options,
                        labels: res.data?.dateLabels
                    },
                    modalData: res.data?.fullDataToModal,
                })
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }
    render() {
        return (
            <div style={{ width: "100%" }}>
                <div style={{ width: "100%", display: "flex", margin: "auto", gap: 5, marginBottom: 32 }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>De/Até</label>
                        <DateRangePicker
                            preventOverflow
                            format="DD/MM/YYYY"
                            locale={locale}
                            defaultValue={this.state.filter?.date}
                            disabled={this.state.isLoading}
                            onChange={(value) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        date: value
                                    }
                                })
                            }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%", marginTop: 20 }}>
                        <Button loading={this.state.isLoading} onClick={() => this.onSubmitButton()} appearance="primary" color="blue"> <i className="fas fa-fw fa-search"></i> Buscar</Button>
                    </div>

                </div>

                <div id="chart">
                    <ReactApexChart options={this.state.options} series={this.state.series} type={this.state.options.chart.type} width={"90%"} />
                </div>

                <Button appearance="primary" color="green" className="mr-2"> <i className="fas fa-fw fa-file-excel"></i> Relatório Excel</Button>
                <Button appearance="primary" color="blue"> <i className="fas fa-fw fa-file-pdf"></i> Relatório PDF</Button>

                <Modal overflow={true} show={this.state.isModalOpen} onHide={this.onCloseModal}>
                    <Modal.Header>
                        <Modal.Title>{this.state.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <List bordered hover>
                            {this.state.selectedModalData.map((item: any, index: any) => (
                                <List.Item key={index} index={index}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>{item.projectCode} - {item.projectDescription}</span>
                                        <div>
                                            <Icon
                                                icon='external-link'
                                                style={{ fontSize: '1.3rem', cursor: 'pointer', transition: '.3s' }}
                                                onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) => {
                                                    (e.target as HTMLElement).style.fontSize = '1.4rem';
                                                }}
                                                onMouseLeave={(e: React.MouseEvent<HTMLSpanElement>) => {
                                                    (e.target as HTMLElement).style.fontSize = '1.3rem';
                                                }}
                                                onClick={() => {
                                                    window.open(`${window.location.origin}/dashboard/project/projects/${item.projectId}`, '_blank');
                                                }}
                                            />
                                        </div>
                                    </div>
                                </List.Item>
                            ))}
                        </List>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.onCloseModal}
                            appearance="primary"
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
