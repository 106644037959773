import React from "react";
import styles from "./styles.module.scss";
import ReactApexChart from "react-apexcharts";
import { Button, CheckPicker, DateRangePicker, Divider, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import _ from "lodash";
import dayjs from "dayjs";
import { formatTime } from "../utils/formatTime";

const Locale = {
    sunday: 'Seg',
    monday: 'Ter',
    tuesday: 'Qua',
    wednesday: 'Qui',
    thursday: 'Sex',
    friday: 'Sab',
    saturday: 'Dom',
    ok: 'OK',
    today: 'Hoje',
    yesterday: 'Ontem',
    last7Days: 'Últimos 7 dias',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos'
};

interface AppointmentChartState { }

export class AppointmentChart extends React.Component<any, AppointmentChartState> {
    public state: any = {
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            },
                            formatter: function (val: any) {
                                return formatTime(val);
                            }
                        }
                    }
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: [],
                labels: {
                    formatter: function (val: any) {
                        return formatTime(val);
                    }
                }
            },
            yaxis: {
                title: {
                    text: undefined
                },
            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return formatTime(val);
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        },
        filters: {
            selectedCreatedDateProject: [
                dayjs().startOf('month').toDate(),
                dayjs().endOf('month').toDate(),
            ],
            selectedProjects: [],
            selectedAppointmentDate: [],
        },
        buttonLoading: false,
        loggedUser: {},
        projects: []
    };


    async componentDidMount() {
        this.setState({
            buttonLoading: true
        })

        await this.handleGetProjects()
        await this.handleSearchGraphsInformation()

        this.setState({
            buttonLoading: false
        })
    }

    handleGetProjects = async () => {
        const projectsResponse = await axios.get('/api/v1/dashboard/get_projects', {
            params: {
                createdDateProject: this.state.filters.selectedCreatedDateProject
            }
        })
        this.setState({
            projects: projectsResponse.data
        })
    }

    handleSearchGraphsInformation = async () => {
        if (this.state.filters.selectedProjects.length === 0 && this.state.filters.selectedCreatedDateProject.length === 0) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha o campo de projeto para buscar as informações",
                duration: 3500
            })

            return;
        }

        this.setState({ buttonLoading: true });

        const { selectedCreatedDateProject, selectedProjects, selectedAppointmentDate } = this.state.filters;

        const graphsResponse = await axios.get("/api/v1/dashboard/get_graphs_information", {
            params: {
                selectedCreatedDateProject,
                selectedProjects,
                selectedAppointmentDate,
            },
        });

        const { projectCategories, projectSeries } = graphsResponse.data;

        const projects = Array.from(new Set(projectSeries.map((item: any) => item.project)));
        const phases = Array.from(new Set(projectSeries.map((item: any) => item.name)));

        const formattedSeries = phases.map((phase) => {
            return {
                name: phase,
                data: projects.map((project) => {
                    const match = projectSeries.find(
                        (item: any) => item.project === project && item.name === phase
                    );
                    return match ? match.data.toFixed(0) : 0;
                }),
            };
        });

        this.setState({
            series: formattedSeries,
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: projectCategories.map((item: any) => item.projectDescription),
                },
            },
            buttonLoading: false,
        });
    };

    render() {
        return (
            <>
                <div className={styles.container}>
                    <div className={styles.filters}>
                        <DateRangePicker
                            style={{ width: '600px' }}
                            value={this.state.filters.selectedCreatedDateProject}
                            placeholder="Data de Criação do Projeto"
                            format="DD/MM/YYYY"
                            locale={Locale}
                            onChange={async (value) => {
                                await this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        selectedCreatedDateProject: value,
                                        selectedProjects: []
                                    }
                                })
                                await this.handleGetProjects()
                            }}
                        />
                        <CheckPicker
                            style={{ width: '100%' }}
                            value={this.state.filters.selectedProjects}
                            data={this.state.projects}
                            placeholder="Selecione um ou mais projetos"
                            locale={{
                                noResultsText: 'Nenhum projeto encontrado...',
                            }}
                            onChange={(value) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        selectedProjects: value,
                                    }
                                })
                            }}
                        />
                        <DateRangePicker
                            style={{ width: '600px' }}
                            value={this.state.filters.selectedAppointmentDate}
                            placeholder="Data do Apontamento"
                            placement="autoHorizontalStart"
                            format="DD/MM/YYYY"
                            locale={Locale}
                            onChange={(value) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        selectedAppointmentDate: value,
                                    }
                                })
                            }}
                        />
                        <Button
                            style={{ width: '250px', height: '36.2px' }}
                            loading={this.state.buttonLoading}
                            onClick={this.handleSearchGraphsInformation}
                            appearance="primary"
                            color="blue"
                        >
                            <i className="fas fa-fw fa-search"></i> Buscar
                        </Button>
                    </div>

                    <Divider>Filtros para o Gráfico de Apontamentos</Divider>

                    <div>
                        <div id="chart">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
                        </div>
                        <div id="html-dist"></div>
                    </div>
                </div>
            </>
        )
    }
}