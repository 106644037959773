import React from "react";
import { Button, CheckPicker, DateRangePicker, Icon, List, Modal } from "rsuite";
import ReactApexChart from "react-apexcharts";
import axios from "../../../utilities/axios";
import dayjs from "dayjs";
import { locale } from "../../../utilities/locale/DateRangePicker";

export class StateReport extends React.Component<any, {}> {
    public state: any = {
        total: 0,
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'pie',
                events: {
                    dataPointSelection: async (event: any, chartContext: any, config: any) => {
                        // const selectedSeriesIndex = config.seriesIndex; // Índice da série
                        // const label = this.state.options.labels[selectedDataPointIndex]; // Rótulo do ponto
                        // const value = this.state.series[selectedDataPointIndex]; // Valor do ponto

                        const selectedDataPointIndex = config.dataPointIndex; // Índice do ponto
                        const { data } = this.state.modal;
                        console.log({ data })

                        let filteredData: any;

                        let modalTitle: string = "";

                        if (selectedDataPointIndex === 0) {
                            modalTitle = "Projetos Aguardando Início"
                            filteredData = data.filter((item: any) => item.project_fk_situation_id === 1)
                        }
                        if (selectedDataPointIndex === 1) {
                            modalTitle = "Projetos Iniciados"
                            filteredData = data.filter((item: any) => item.project_fk_situation_id === 2)
                        }
                        if (selectedDataPointIndex === 2) {
                            modalTitle = "Projetos Urgentes"
                            filteredData = data.filter((item: any) => item.project_fk_situation_id === 4)
                        }
                        if (selectedDataPointIndex === 3) {
                            modalTitle = "Projetos Finalizados"
                            filteredData = data.filter((item: any) => item.project_fk_situation_id === 5)
                        }

                        modalTitle = `${modalTitle} - ${dayjs(this.state.filter.date[0]).format("DD/MM/YYYY")} ~ ${dayjs(this.state.filter.date[1]).format("DD/MM/YYYY")}`

                        await this.setState({
                            modal: {
                                ...this.state.modal,
                                modalTitle: modalTitle,
                                filteredData: filteredData
                            }
                        })

                        this.onOpenModal()
                    }
                }
            },
            labels: ['AGUARDANDO INICIO', 'INICIADO', 'URGENTE', 'FINALIZADO'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
        filter: {
            status: [],
            date: [dayjs().startOf('month'), dayjs().endOf('month')]
        },
        isLoading: false,
        stream: null,
        modal: {
            modalOpen: false,
            modalTitle: "",
            data: [],
            filteredData: []
        }
    }

    componentDidMount(): void {
        this.onSubmitButton()
    }

    onCloseModal = () => {
        this.setState({
            modal: {
                ...this.state.modal,
                modalOpen: false
            }
        })
    }

    onOpenModal = () => {
        this.setState({
            modal: {
                ...this.state.modal,
                modalOpen: true
            }
        })
    }

    onSubmitButton = () => {
        this.setState({ isLoading: true })
        axios.get("/api/v1/projects/reports/stateprojects", {
            params: {
                filter: this.state.filter,
                stream: this.state.stream
            }
        }).then((res) => {
            if (res?.status == 200) {
                this.setState({
                    series: res.data?.series,
                    total: res.data?.projectCount,
                    modal: {
                        ...this.state.modal,
                        data: res.data.responseProjects
                    }
                })
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }
    render() {
        return (
            <div style={{ width: "100%" }}>
                <div style={{ width: "100%", display: "flex", margin: "auto", gap: 5, marginBottom: 32 }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>De/Até</label>
                        <DateRangePicker
                            preventOverflow
                            format="DD/MM/YYYY"
                            locale={locale}
                            defaultValue={this.state.filter?.date}
                            disabled={this.state.isLoading}
                            onChange={(value) => {
                                this.setState({
                                    ...this.state.filter, filter: {
                                        date: value
                                    }
                                })
                            }} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>Status</label>
                        <CheckPicker disabled={this.state.isLoading} placeholder="Selecione..." defaultValue={[]} onChange={(value) => {
                            this.setState({
                                ...this.state.filter, filter: {
                                    status: value
                                }
                            })
                        }} data={[{
                            value: 1,
                            label: "AGUARDANDO INICIO"
                        }, {
                            value: 2,
                            label: "INICIADO"
                        }, {
                            value: 4,
                            label: "URGENTE"
                        }, {
                            value: 5,
                            label: "FINALIZADO"
                        }
                        ]} />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "50%", marginTop: 20 }}>
                        <Button loading={this.state.isLoading} onClick={() => { this.setState({ stream: null }); this.onSubmitButton() }} appearance="primary" color="blue"> <i className="fas fa-fw fa-search"></i> Buscar</Button>
                    </div>


                </div>
                <div id="chart">
                    <ReactApexChart
                        options={this.state.options}
                        series={this.state.series}
                        type={this.state.options.chart.type}
                        width={380}
                    />
                </div>
                <Button loading={this.state.isLoading} onClick={() => { this.setState({ stream: "excel" }); this.onSubmitButton() }} appearance="primary" color="green" className="mr-2"> <i className="fas fa-fw fa-file-excel"></i> Relatório Excel</Button>
                <Button loading={this.state.isLoading} onClick={() => { this.setState({ stream: "pdf" }); this.onSubmitButton() }} appearance="primary" color="blue"> <i className="fas fa-fw fa-file-pdf"></i> Relatório PDF</Button>

                <Modal overflow={true} show={this.state.modal.modalOpen} onHide={this.onCloseModal}>
                    <Modal.Header>
                        <Modal.Title>{this.state.modal.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <List bordered hover>
                            {this.state.modal.filteredData.map((item: any, index: any) => (
                                <List.Item key={index} index={index}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>{item.project_code} - {item.project_description}</span>
                                        <div>
                                            <Icon
                                                icon='external-link'
                                                style={{ fontSize: '1.3rem', cursor: 'pointer', transition: '.3s' }}
                                                onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) => {
                                                    (e.target as HTMLElement).style.fontSize = '1.4rem';
                                                }}
                                                onMouseLeave={(e: React.MouseEvent<HTMLSpanElement>) => {
                                                    (e.target as HTMLElement).style.fontSize = '1.3rem';
                                                }}
                                                onClick={() => {
                                                    window.open(`${window.location.origin}/dashboard/project/projects/${item.project_id}`, '_blank');
                                                }}
                                            />
                                        </div>
                                    </div>
                                </List.Item>
                            ))}
                        </List>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.onCloseModal}
                            appearance="primary"
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
