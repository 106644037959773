import React from "react";
import { Panel } from "rsuite";
import { StateReport } from "./reportPages/StateReport";
import { StateOpenClosedReport } from "./reportPages/StateOpenClosedReport";
import { StateReportTopUrgent } from "./reportPages/StateReportTopUrgent";
import { StateReportTopOpenDay } from "./reportPages/StateReportTopOpenDay";
import { StateReportTopOpenMonth } from "./reportPages/StateReportTopOpenMonth";
import { AppointmentChart } from "../graphs/AppointmentChart/AppointmentChart";
import { Helmet } from "react-helmet";
import { UserAppointmentChart } from "../graphs/UserAppointmentChart/UserAppointmentChart";

export class ProjectReports extends React.Component<any, {}> {
    render(): React.ReactNode {
        return (

            <div className="container" style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }}>
                <Helmet>
                    <title>FastFlow - Dashboard</title>
                </Helmet>
                <div style={{ width: "100%", margin: 'auto', display: "flex", gap: 5 }}>
                    <Panel header={<b>Status de Projeto</b>} style={{ background: "white", width: "100%" }}>
                        <StateReport />
                    </Panel>

                    <Panel header={<b>Top projetos mais marcados como urgente</b>} style={{ background: "white", width: "100%" }}>
                        <StateReportTopUrgent />
                    </Panel>
                </div>
                <div style={{ width: "100%", margin: 'auto', display: "flex", gap: 5, marginTop: 15 }}>
                    <Panel header={<b>+ Projetos abertos por dia</b>} style={{ background: "white", width: "100%" }}>
                        <StateReportTopOpenDay />
                    </Panel>

                    <Panel header={<b>+ Projetos abertos por mes</b>} style={{ background: "white", width: "100%" }}>
                        <StateReportTopOpenMonth />
                    </Panel>
                </div>

                <div style={{ width: "100%", margin: 'auto', display: "flex", gap: 5, marginTop: 15 }}>
                    <Panel header={<b>Projetos abertos x finalizados</b>} style={{ background: "white", width: "100%" }}>
                        <StateOpenClosedReport />
                    </Panel>
                </div>

                <div style={{ width: "100%", margin: 'auto', display: "flex", gap: 5, marginTop: 15 }}>
                    <Panel header={<b>Gráfico de Apontamentos por Projeto</b>} style={{ background: "white", width: "100%" }}>
                        <AppointmentChart />
                    </Panel>
                </div>

                <div style={{ width: "100%", margin: 'auto', display: "flex", gap: 5, marginTop: 15 }}>
                    <Panel header={<b>Gráfico de Apontamentos por Usuário</b>} style={{ background: "white", width: "100%" }}>
                        <UserAppointmentChart />
                    </Panel>
                </div>
            </div>
        )
    }
}
