import { Button, CheckPicker, DateRangePicker, Icon, List, Modal } from "rsuite";
import styles from "./styles.module.scss";
import React from "react";
import { locale } from "../../../utilities/locale/DateRangePicker";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import axios from "../../../utilities/axios";
import { formatMinutesToHoursAndMinutes } from "../utils/formatMinutesToHoursAndMinutes";

interface UserAppointmentChartState { }

export class UserAppointmentChart extends React.Component<any, UserAppointmentChartState> {
    public state: any = {
        series: [{
            data: []
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: async (event: any, chartContext: any, config: any) => {
                        const clickedCategory = this.state.options.xaxis.categories[config.dataPointIndex];
                        const { fullData } = this.state;

                        let modalData: any = [];

                        fullData.forEach((item: any) => {
                            if (item?.userName === clickedCategory) {
                                modalData.push(item)
                            }
                        });

                        const projects: string[] = [];
                        let appointmentTime: any[] = [];
                        const projectsIds: number[] = [];

                        modalData.forEach((item: any) => {
                            const key = `${item?.projectCode} - ${item?.projectName}`;

                            if (projects.includes(key)) {
                                const index = projects.indexOf(key)
                                appointmentTime[index] = appointmentTime[index] + item?.calculatedTime
                            } else {
                                projects.push(key)
                                appointmentTime.push(item?.calculatedTime)
                                projectsIds.push(item?.projectId)
                            }
                        })

                        appointmentTime = appointmentTime.map((item: number) => formatMinutesToHoursAndMinutes(item))

                        if (config.dataPointIndex !== -1) {
                            this.setState({
                                isModalOpen: true,
                                modalCategories: projects,
                                modalSeries: appointmentTime,
                                modalProjectIds: projectsIds
                            });
                        }
                    }
                }
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
                    const seriesName = "Total";
                    const value = formatMinutesToHoursAndMinutes(series[seriesIndex][dataPointIndex]);
                    const category = w.globals.labels[dataPointIndex];
                    return `
                        <div style="padding: 10px; border: 1px solid #ccc; background: #fff; border-radius: 5px;">
                            <strong>${category}</strong><br />
                            ${seriesName}: ${value}
                        </div>
                    `;
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '.8rem',
                    colors: ['#000'],
                },
                formatter: function (val: number) {
                    return formatMinutesToHoursAndMinutes(val);
                },
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: [],
                labels: {
                    style: {
                        colors: [],
                        fontSize: '12px'
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: function (val: number) {
                        return Math.round(val);
                    }
                }
            },
        },
        buttonLoading: false,
        filters: {
            selectedCreatedDateProject: [
                dayjs().startOf('month').toDate(),
                dayjs().endOf('month').toDate(),
            ],
            selectedProjects: [],
            selectedUsers: []
        },
        projects: [],
        users: [],
        isModalOpen: false,
        fullData: [],
        modalCategories: [],
        modalSeries: [],
        modalProjectIds: [],
    }

    async componentDidMount() {
        await this.handleSearchGraphsInformation()
        await this.handleGetProjects()
        await this.handleGetUsers()
    }

    handleGetUsers = async () => {
        const usersResponse = await axios.get("/api/v1/dashboard/get_users")

        this.setState({
            users: usersResponse.data
        })
    }

    handleSearchGraphsInformation = async () => {
        this.setState({
            buttonLoading: true
        })

        const { selectedCreatedDateProject, selectedProjects, selectedUsers } = this.state.filters;

        const usersResponse = await axios.get("/api/v1/dashboard/get_userGraphsAppointment_information", {
            params: {
                selectedCreatedDateProject,
                selectedProjects,
                selectedUsers,
            }
        })

        this.setState({
            series: [{
                data: usersResponse.data.series
            }],
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: usersResponse.data.categories
                }
            },
            buttonLoading: false,
            fullData: usersResponse.data.fullData
        })
    }

    handleGetProjects = async () => {
        const projectsResponse = await axios.get('/api/v1/dashboard/get_projects', {
            params: {
                createdDateProject: this.state.filters.selectedCreatedDateProject
            }
        })

        this.setState({
            projects: projectsResponse.data
        })
    }

    onCloseModal = async () => {
        this.setState({
            isModalOpen: false
        })
    }

    render() {
        return (
            <>
                <div className={styles.container}>
                    <div className={styles.filters}>
                        <div style={{ width: '600px' }}>
                            <DateRangePicker
                                style={{ width: '100%' }}
                                placeholder="Data de Criação do Projeto"
                                format="DD/MM/YYYY"
                                locale={locale}
                                value={this.state.filters.selectedCreatedDateProject}
                                onChange={async (value) => {
                                    await this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            selectedCreatedDateProject: value,
                                            selectedProjects: []
                                        }
                                    })
                                    await this.handleGetProjects()
                                }}
                            />

                            <span className={styles.textInformation}>*Sem data, todo o período será usado</span>
                        </div>


                        <div style={{ width: '100%' }}>
                            <CheckPicker
                                style={{ width: '100%' }}
                                data={this.state.projects}
                                placeholder="Selecione um ou mais projetos"
                                locale={{
                                    noResultsText: 'Nenhum projeto encontrado...',
                                }}
                                value={this.state.filters.selectedProjects}
                                onChange={(value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            selectedProjects: value,
                                        }
                                    })
                                }}
                            />

                            <span className={styles.textInformation}>*Sem seleção, todos os projetos serão usados</span>
                        </div>

                        <div style={{ width: '100%' }}>
                            <CheckPicker
                                style={{ width: '100%' }}
                                data={this.state.users}
                                placeholder="Selecione um ou mais usuários"
                                locale={{
                                    noResultsText: 'Nenhum projeto encontrado...',
                                }}
                                value={this.state.filters.selectedUsers}
                                onChange={(value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            selectedUsers: value,
                                        }
                                    })
                                }}
                            />

                            <span className={styles.textInformation}>*Sem seleção, todos os usuários serão usados</span>
                        </div>

                        <Button
                            appearance="primary"
                            color="blue"
                            style={{ width: '250px', height: '36.2px' }}
                            loading={this.state.buttonLoading}
                            onClick={this.handleSearchGraphsInformation}
                        >
                            <i className="fas fa-fw fa-search"></i> Buscar
                        </Button>
                    </div>
                    <div className={styles.chartContainer}>
                        <div>
                            <div id="chart">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
                            </div>
                            <div id="html-dist"></div>
                        </div>
                    </div>
                </div>

                <Modal
                    overflow={true}
                    show={this.state.isModalOpen}
                    onHide={this.onCloseModal}
                >
                    <Modal.Header>
                        <Modal.Title>Informações Detalhadas</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <List bordered hover>
                            {this.state.modalCategories.map((item: any, index: any) => (
                                <List.Item key={index} index={index}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>{item} - {this.state.modalSeries[index] !== "" ? this.state.modalSeries[index] : "0 min"}</span>
                                        <div>
                                            <Icon
                                                icon='external-link'
                                                style={{ fontSize: '1.3rem', cursor: 'pointer', transition: '.3s' }}
                                                onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) => {
                                                    (e.target as HTMLElement).style.fontSize = '1.4rem';
                                                }}
                                                onMouseLeave={(e: React.MouseEvent<HTMLSpanElement>) => {
                                                    (e.target as HTMLElement).style.fontSize = '1.3rem';
                                                }}
                                                onClick={() => {
                                                    window.open(`${window.location.origin}/dashboard/project/projects/${this.state.modalProjectIds[index]}`, '_blank');
                                                }}
                                            />
                                        </div>
                                    </div>
                                </List.Item>
                            ))}
                        </List>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.onCloseModal}
                            appearance="primary"
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}