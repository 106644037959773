import React from "react";
import { Button, DateRangePicker, Icon, List, Modal } from "rsuite";
import ReactApexChart from "react-apexcharts";
import axios from "../../../utilities/axios";
import dayjs from "dayjs";
import { locale } from "../../../utilities/locale/DateRangePicker";

export class StateReportTopOpenDay extends React.Component<any, {}> {
    public state: any = {
        total: 0,
        series: [
            {
                name: "Projetos",
                data: [],
            },
        ],
        options: {
            chart: {
                height: 350,
                type: "line",
                zoom: { enabled: false },
                events: {
                    click: (event: any, chartContext: any, config: any) => {
                        this.setState({
                            showModal: true
                        })
                    },
                },
            },
            dataLabels: { enabled: false },
            stroke: { curve: "straight" },
            grid: {
                row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 },
            },
            xaxis: {
                categories: [""],
            },
        },
        filter: {
            status: [],
            date: [dayjs().startOf("month"), dayjs().endOf("month")],
        },
        isLoading: false,
        showModal: false,
        selectedData: null,
        fullData: []
    };

    componentDidMount(): void {
        this.onSubmitButton();
    }

    onSubmitButton = () => {
        this.setState({ isLoading: true });
        axios
            .get("/api/v1/projects/reports/topprojectsopenday", {
                params: { filter: this.state.filter },
            })
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        series: res.data?.series,
                        options: {
                            ...this.state.options,
                            xaxis: res.data?.xaxis,
                        },
                        fullData: res.data?.fullData
                    });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    render() {
        return (
            <div style={{ width: "100%" }}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        margin: "auto",
                        gap: 5,
                        marginBottom: 32,
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <label>De/Até</label>
                        <DateRangePicker
                            preventOverflow
                            format="DD/MM/YYYY"
                            locale={locale}
                            defaultValue={this.state.filter?.date}
                            disabled={this.state.isLoading}
                            onChange={(value) => {
                                this.setState({
                                    ...this.state.filter,
                                    filter: { date: value },
                                });
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "50%",
                            marginTop: 20,
                        }}
                    >
                        <Button
                            loading={this.state.isLoading}
                            onClick={() => this.onSubmitButton()}
                            appearance="primary"
                            color="blue"
                        >
                            <i className="fas fa-fw fa-search"></i> Buscar
                        </Button>
                    </div>
                </div>

                <div id="chart">
                    <ReactApexChart
                        options={this.state.options}
                        series={this.state.series}
                        type={this.state.options.chart.type}
                        width={380}
                    />
                </div>

                <Button appearance="primary" color="green" className="mr-2">
                    <i className="fas fa-fw fa-file-excel"></i> Relatório Excel
                </Button>
                <Button appearance="primary" color="blue">
                    <i className="fas fa-fw fa-file-pdf"></i> Relatório PDF
                </Button>

                <Modal
                    overflow={true}
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false })}
                >
                    <Modal.Header>
                        <Modal.Title>Detalhes dos Projetos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <List bordered hover>
                            {this.state.fullData.map((item: any, index: any) => (
                                <List.Item key={index} index={index}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>{item.projectCode} - {item.projectDescription}</span>
                                        <div>
                                            <Icon
                                                icon='external-link'
                                                style={{ fontSize: '1.3rem', cursor: 'pointer', transition: '.3s' }}
                                                onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) => {
                                                    (e.target as HTMLElement).style.fontSize = '1.4rem';
                                                }}
                                                onMouseLeave={(e: React.MouseEvent<HTMLSpanElement>) => {
                                                    (e.target as HTMLElement).style.fontSize = '1.3rem';
                                                }}
                                                onClick={() => {
                                                    window.open(`${window.location.origin}/dashboard/project/projects/${item.projectId}`, '_blank');
                                                }}
                                            />
                                        </div>
                                    </div>
                                </List.Item>
                            ))}
                        </List>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => this.setState({ showModal: false })}
                            appearance="primary"
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}