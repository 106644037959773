import React, { ReactNode } from "react";
import axios from "../../../../../utilities/axios";
import { Button, Icon, Notification, Placeholder, Tag, Tooltip, Whisper } from "rsuite";
import axiosClientChat from "../../../../../utilities/axiosClientChat";
import { UserContext } from "../../../../authProvider/main";
import dayjs from "dayjs";
import { ConfirmModal } from "../../../../confirmModal/ConfirmModal";
import { ChatUpdateStatus } from "./ChatUpdateStatus";
import { getSituationChatIcon } from "../../../../../utilities/situations/situationsFunctions";
import { motion } from "framer-motion";
import { zoomIn } from "../../../../motion/motionSettings";
import { io } from "../../../../../utilities/io";

interface ChatComponentListProps {
    params: {
        project_product_id: string | number,
        project_product_fk_product_id: string | number
    }
    selectItem?: ({ data }: { data: Record<string, any> }) => void;
    typeCall: 'webUser' | 'webPerson';
}

interface ChatComponentListState {
    data: any,
    loading: boolean,
    loadingButtons: Record<number, boolean>;
    appointment: any,
    showDialogModal: boolean,
    dialogIndex: number | null,
    dialogParams: any | null,
    modalDialogText: string,
    elapsedTime: {
        [key: number]: {
            hours: number;
            minutes: number;
            seconds: number;
        };
    };
    idRoom: any;
    loadingSituationButton: boolean;
}

export class ChatComponentList extends React.Component<any, ChatComponentListState> {

    static contextType = UserContext;

    private intervalId: NodeJS.Timeout | null = null;

    public state: ChatComponentListState = {
        data: [],
        loading: true,
        loadingButtons: {},
        appointment: {},
        showDialogModal: false,
        dialogIndex: null,
        dialogParams: null,
        modalDialogText: "",
        elapsedTime: {},
        idRoom: null,
        loadingSituationButton: false
    };

    componentDidMount(): void {
        if (this.props.typeCall === 'webUser') {
            axios.get(`/api/v1/steps/${this.props.params.project_product_id}/${this.props.params.project_product_fk_product_id}/get`)
                .then((response) => {
                    this.setState({ data: response.data.item, loading: false })
                    this.getIdRoomForAllItems();
                })
        }
        if (this.props.typeCall === "webPerson") {
            axiosClientChat.get(`/chat-client/steps/${this.props.params.project_product_id}/${this.props.params.project_product_fk_product_id}/get`)
                .then((response) => {
                    // console.log({ response })
                    this.setState({ data: response.data.item, loading: false })
                })
        }

        this.startInterval();
    }

    getIdRoomForAllItems = () => {
        this.state.data.forEach((value: any) => {
            this.getIdRoom(value);
        });
    }

    componentWillUnmount(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    startInterval = () => {
        this.intervalId = setInterval(() => {
            this.updateElapsedTimes();
        }, 1000);
    };

    TwentyTimesParagraphs = () => {
        // Cria um array com 20 elementos vazios
        const array = Array.from({ length: 4 });

        return (
            <>
                {array.map((_, index) => (
                    <Placeholder.Paragraph key={index} graph='square' active />
                ))}
            </>
        );
    };

    selectItem = ({ data }: { data: Record<string, any> }) => {
        // console.log("debugs selectItem", data)
        if (this.props.selectItem) {
            this.props.selectItem({ data: data })
        }
    }

    updateElapsedTimes = () => {
        this.setState((prevState) => {
            const newElapsedTime = prevState.data.reduce((acc: any, value: any, index: number) => {
                const init = value.steps[0].appointments?.project_product_appointment_init;
                const finish = value.steps[0].appointments?.project_product_appointment_finish;

                if (init) {
                    acc[index] = this.getElapsedTime(init);
                }
                if (finish) {
                    acc[index] = this.getElapsedTime(finish);
                }

                return acc;
            }, {});
            return { elapsedTime: newElapsedTime };
        });
    };

    getElapsedTime(initialTime: string) {
        const startTime = dayjs(initialTime);
        const currentTime = dayjs();

        const hours = currentTime.diff(startTime, 'hour');
        const minutes = currentTime.diff(startTime, 'minute') % 60;
        const seconds = currentTime.diff(startTime, 'second') % 60;

        return { hours, minutes, seconds };
    }

    getPausedTime(initialTime: string, pauseTime: string) {
        const startTime = dayjs(initialTime);
        const currentTime = dayjs(pauseTime);

        const hours = currentTime.diff(startTime, 'hour');
        const minutes = currentTime.diff(startTime, 'minute') % 60;
        const seconds = currentTime.diff(startTime, 'second') % 60;

        return { hours, minutes, seconds };
    }

    getIdRoom(paramsRoute: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.state.idRoom && this.state.idRoom[paramsRoute.project_product_group_step_table_flow_fk_project_product_id]) {
                resolve(this.state.idRoom[paramsRoute.project_product_group_step_table_flow_fk_project_product_id]);
                return;
            }

            this.setState({ loadingSituationButton: true });
            io.emit("ChoseRoomChat", {
                params: {
                    product_id: paramsRoute?.project_product_group_step_table_flow_fk_product_id || null,
                    project_id: paramsRoute?.project_id,
                    project_product_id: paramsRoute?.project_product_group_step_table_flow_fk_project_product_id || null,
                    step_item: { ...paramsRoute.steps[0] }
                },
                token: localStorage.getItem('token'),
                isWebUser: true
            }, (response: any) => {
                this.setState({ idRoom: response.idRoom, loadingSituationButton: false });
                resolve(response.idRoom);
            });
        });
    }

    verifyIsInitiated = async (event: React.MouseEvent, parameters: any, index: number) => {
        event.stopPropagation();

        const response = await axios.get('/api/v1/appointment/current');
        // console.log("debugs current", response.data.currentAppointment);
        const { currentAppointments } = response.data;

        if (currentAppointments) {
            for (const currentAppointment of currentAppointments) {
                if (currentAppointment && currentAppointment?.project_product_appointment_status === "EM ANDAMENTO" && !currentAppointment?.project_product_appointment_finish) {
                    Notification.info({
                        title: 'Aviso',
                        description: "Não é possível iniciar este apontamento, pois já existe outro apontamento em andamento para o mesmo usuário.",
                        duration: 5000
                    });
                    return;
                }
            }
        }

        // console.log("iniciar apont", this.state.data[index].steps[0])
        if (this.state.data[index].steps[0].situation_chat_name === "NÃO INICIADO" || this.state.data[index].steps[0].situation_chat_name === null || this.state.data[index].steps[0].situation_chat_name === undefined) {
            this.setState({
                showDialogModal: true,
                dialogIndex: index,
                dialogParams: parameters,
                modalDialogText: "Essa Fase ainda não foi Iniciada, deseja inicia-la para iniciar seu apontamento?"
            });

            return
        }

        if (this.state.data[index].steps[0].situation_chat_name === "FINALIZADO") {
            this.setState({
                showDialogModal: true,
                dialogIndex: index,
                dialogParams: parameters,
                modalDialogText: "Essa Fase já foi Finalizada. Deseja continuar com sua finalização?"
            });

            return
        }

        this.initAppointment(index, parameters);
    }

    initAppointment = async (index: number | null, parameters: any | null) => {
        if (index === null || parameters === null) return;

        this.setState(prevState => ({
            loadingButtons: {
                ...prevState.loadingButtons,
                [index]: true
            },
            showDialogModal: false,
            dialogIndex: null,
            dialogParams: null,
        }));

        let createdChat: any;
        try {
            console.log("start-non-initiated-chat", this.state.data[index].steps[0].chat_message_id);

            if (this.state.data[index].steps[0].chat_message_id === undefined) {
                createdChat = await this.getIdRoom(this.state.data[index]);
                console.log({ createdChat });
                this.setState(prevState => ({
                    data: prevState.data.map((item: any, i: number) =>
                        i === index
                            ? { ...item, steps: [{ ...item.steps[0], chat_message_id: createdChat, situation_chat_name: "NÃO INICIADO" }] }
                            : item
                    )
                }), () => {
                    this.forceUpdate();
                });
            }

            // console.log("debugs chat_messages", this.state.data[index].steps[0]);
            if (this.state.data[index].steps[0].situation_chat_name === "NÃO INICIADO" || this.state.data[index].steps[0].situation_chat_name === null) {
                const response = await axios.get(`/api/v1/situation/chat/1/${this.state.data[index].steps[0].chat_message_id}/true`);
                if (response.data.message) {
                    Notification.success({
                        title: 'Sucesso!',
                        description: response.data.message
                    });
                }
            }

            await axios.post(`/api/v1/appointment/start`, { params: { ...parameters } })
                .then((response) => {
                    const { message } = response.data;
                    this.setState({ appointment: response.data });
                    Notification.info({ title: "Lembrete", description: message, duration: 5000 });
                });

        } catch (err: any) {
            if (err.response) {
                Notification.warning({
                    title: "Aviso!",
                    description: err.response.data.message,
                    duration: 8000
                });
            } else {
                Notification.error({
                    title: "Erro",
                    description: "Ocorreu um erro inesperado."
                });
            }
            return;
        } finally {
            this.setState(prevState => ({
                loadingButtons: {
                    ...prevState.loadingButtons,
                    [index]: false
                }
            }));
        }

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    pauseAppointment = (event: React.MouseEvent, parameters: any, index: number) => {
        event.stopPropagation();
        this.setState(prevState => ({
            loadingButtons: {
                ...prevState.loadingButtons,
                [index]: true
            }
        }));

        axios.post(`/api/v1/appointment/pause`, { params: { ...parameters } })
            .then((response) => {
                const { message } = response.data;
                this.setState({ appointment: response.data });
                Notification.info({ title: "Lembrete", description: message, duration: 5000 });
            }).catch((e) => {
                const { message } = e;
                Notification.error({ title: message });
            }).finally(() => {
                this.setState(prevState => ({
                    loadingButtons: {
                        ...prevState.loadingButtons,
                        [index]: false
                    }
                }));
            });

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    resumeAppointment = async (event: React.MouseEvent, parameters: any, index: number) => {
        event.stopPropagation();

        const response = await axios.get('/api/v1/appointment/current');
        // console.log("debugs current", response.data.currentAppointment);
        const { currentAppointments } = response.data;

        if (currentAppointments) {
            for (const currentAppointment of currentAppointments) {
                if (currentAppointment && currentAppointment?.project_product_appointment_status === "EM ANDAMENTO" && !currentAppointment?.project_product_appointment_finish) {
                    Notification.info({
                        title: 'Aviso',
                        description: "Não é possível retomar este apontamento, pois já existe outro apontamento em andamento para o mesmo usuário.",
                        duration: 5000
                    });
                    return;
                }
            }
        }

        this.setState(prevState => ({
            loadingButtons: {
                ...prevState.loadingButtons,
                [index]: true
            }
        }));

        axios.post(`/api/v1/appointment/resume`, { params: { ...parameters } })
            .then((response) => {
                const { message } = response.data;
                this.setState({ appointment: response.data });
                Notification.info({ title: "Lembrete", description: message, duration: 5000 });
            }).catch((e) => {
                const { message } = e;
                Notification.error({ title: message });
            }).finally(() => {
                this.setState(prevState => ({
                    loadingButtons: {
                        ...prevState.loadingButtons,
                        [index]: false
                    }
                }));
            });

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    finishAppointment = (event: React.MouseEvent, parameters: any, index: number) => {
        event.stopPropagation();
        this.setState(prevState => ({
            loadingButtons: {
                ...prevState.loadingButtons,
                [index]: true
            }
        }));

        axios.post(`/api/v1/appointment/finish`, { params: { ...parameters } })
            .then((response) => {
                const { message } = response.data;
                this.setState({ appointment: response.data });
                Notification.info({ title: "Lembrete", description: message, duration: 5000 });
            }).catch((e) => {
                const { message } = e;
                Notification.error({ title: message });
            }).finally(() => {
                this.setState(prevState => ({
                    loadingButtons: {
                        ...prevState.loadingButtons,
                        [index]: false
                    }
                }));
            });

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    renderChats = () => {
        const user = this.context.data;

        // const anyPhaseInProgress = this.state.data.some((value: any) => {
        //     // console.log("debugs aparecer botoes", value.steps[0].appointments)
        //     const status = value.steps[0].appointments?.project_product_appointment_status;
        //     if (status === "EM ANDAMENTO") {
        //         return true;
        //     }
        //     return false;
        // });

        return this.state.data.map((value: any, index: number) => {
            const isLoading = this.state.loadingButtons[index] || false;

            const init = value.steps[0].appointments?.project_product_appointment_init;
            const finish = value.steps[0].appointments?.project_product_appointment_finish;

            const status = value.steps[0].appointments?.project_product_appointment_status;

            let currentStatus;
            let currentStatusText = "NÃO INICIADO";
            if (init) {
                currentStatus = init;
            }
            if (finish) {
                currentStatus = finish;
            }

            currentStatusText = status;
            // console.log({ finish, currentStatusText });
            const currentTime = currentStatus ? dayjs(currentStatus).format('DD/MM/YYYY HH:mm:ss') : null;
            const elapsedTime = this.state.elapsedTime[index];

            // console.log({ value });
            return (
                <motion.div initial="hidden" animate="visible" variants={zoomIn}>
                    <div
                        style={
                            {
                                width: '100%',
                                minHeight: '4rem',
                                // borderRadius: 10,
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: value?.steps[0].situation_chat_color ? value?.steps[0].situation_chat_color : '#0d5452',
                                cursor: 'pointer'
                            }
                        }
                        onClick={() => this.selectItem({ data: value })}
                    >
                        <div
                            style={
                                {
                                    width: '8%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }
                            }
                        >
                            <i className="far fa-comments fa-2x " style={{ color: '#fff' }}></i>
                            <p
                                style={{ color: "#fff" }}
                            >
                                {value?.steps[0].messages_not_viewed?.count ? value?.steps[0].messages_not_viewed?.count : 0}
                            </p>
                        </div>
                        <div
                            style={
                                {
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    flexWrap: 'wrap',
                                    background: '#f5f5f5',
                                    paddingLeft: 10,
                                    // borderRadius: 10,
                                }
                            }
                        >
                            <h6>
                                {value.steps[0].step_name}
                                {value.envolved_users.includes(user.user_id) && (
                                    <div style={{ float: "right", marginRight: 15, marginTop: 12 }}>
                                        {/* {(elapsedTime?.seconds >= 0 && currentStatusText !== "FINALIZADO") && (
                                        <div style={{ fontSize: 12 }}>
                                            {elapsedTime.hours > 0 && `${elapsedTime.hours}h `}
                                            {elapsedTime.minutes > 0 && `${elapsedTime.minutes}m `}
                                            {elapsedTime.seconds}s
                                        </div>
                                    )} */}
                                        <div style={{ flexDirection: "row", display: "flex" }}>
                                            <div style={{ marginRight: 5 }} onClick={(e) => { e?.stopPropagation(); this.getIdRoom(value) }}>
                                                <ChatUpdateStatus
                                                    {...this.props}
                                                    placement="bottomEnd"
                                                    projectProductId={value.project_product_group_step_table_flow_fk_project_product_id}
                                                    ppgstfId={value.project_product_group_step_table_flow_id}
                                                    chatId={value.steps[0].chat_message_id ? value.steps[0].chat_message_id : this.state.idRoom}
                                                    parentState={this.state}
                                                    isLoadingButton={this.state.loadingSituationButton}
                                                />
                                            </div>
                                            <div style={{ flexDirection: "row", display: "grid" }}>
                                                {/* <ButtonGroup> */}
                                                {(value?.steps[0].situation_chat_name !== "FINALIZADO" && currentStatusText !== "EM ANDAMENTO" && currentStatusText !== "PAUSADO") && (
                                                    <Whisper trigger="hover" placement="bottom" speaker={
                                                        <Tooltip placement="topStart">
                                                            Iniciar Apontamento
                                                        </Tooltip>
                                                    }>
                                                        <Button
                                                            style={{ width: 150 }}
                                                            size="md"
                                                            color="green"
                                                            // placement="right"
                                                            loading={isLoading}
                                                            onClick={(event: any) => this.verifyIsInitiated(event, value, index)}
                                                        >
                                                            <Icon icon="arrow-right" /> Iniciar
                                                        </Button>
                                                    </Whisper>
                                                )}
                                                {(currentStatusText === "EM ANDAMENTO") && (
                                                    <Whisper trigger="hover" placement="top" speaker={
                                                        <Tooltip placement="topStart">
                                                            Pausar Apontamento
                                                        </Tooltip>
                                                    }>
                                                        <Button
                                                            style={{ width: 150, marginBottom: 3 }}
                                                            size="md"
                                                            color="orange"
                                                            // placement="left"
                                                            loading={isLoading}
                                                            onClick={(event: any) => this.pauseAppointment(event, value, index)}
                                                        >
                                                            <Icon icon="pause" /> Pausar - {(elapsedTime?.seconds >= 0) && (
                                                                <>
                                                                    {elapsedTime.hours > 0 && `${elapsedTime.hours}h `}
                                                                    {elapsedTime.minutes > 0 && `${elapsedTime.minutes}m `}
                                                                    {elapsedTime.seconds}s
                                                                </>
                                                            )}
                                                        </Button>
                                                    </Whisper>
                                                )}
                                                {/* </ButtonGroup> */}
                                                {(currentStatusText === "PAUSADO") && (
                                                    <Whisper trigger="hover" placement="top" speaker={
                                                        <Tooltip placement="topStart">
                                                            Retomar Apontamento
                                                        </Tooltip>
                                                    }>
                                                        <Button
                                                            style={{ width: 150, marginBottom: 3 }}
                                                            size="md"
                                                            color="blue"
                                                            // placement="right"
                                                            loading={isLoading}
                                                            onClick={(event: any) => this.resumeAppointment(event, value, index)}
                                                        >
                                                            <Icon icon="arrow-right" /> Retomar
                                                        </Button>
                                                    </Whisper>
                                                )}
                                                {(["EM ANDAMENTO", "PAUSADO"].includes(currentStatusText)) && (
                                                    <Whisper trigger="hover" placement="bottom" speaker={
                                                        <Tooltip placement="topStart">
                                                            Finalizar Apontamento
                                                        </Tooltip>
                                                    }>
                                                        <Button
                                                            style={{ width: 150 }}
                                                            size="md"
                                                            color="red"
                                                            // placement="left"
                                                            loading={isLoading}
                                                            onClick={(event: any) => this.finishAppointment(event, value, index)}
                                                        >
                                                            <Icon icon="stop" /> Finalizar
                                                        </Button>
                                                    </Whisper>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </h6>
                            {value?.steps[0].step_id !== 9 && (
                                <p style={{ marginBottom: value?.steps[0]?.situation_chat_name === "FINALIZADO" || !value?.steps[0]?.situation_chat_name ? 10 : 0 }}>
                                    Status da Fase: <Tag style={{ backgroundColor: value?.steps[0].situation_chat_color ? value?.steps[0].situation_chat_color : '#0d5452', color: "#FFF", fontWeight: "bold" }}>
                                        {value?.steps[0]?.situation_chat_name ? value?.steps[0]?.situation_chat_name : 'NÃO INICIADO'} <i className={getSituationChatIcon(value?.steps[0].situation_chat_name ? value?.steps[0].situation_chat_name : '#0d5452').replace("fa-2x", "")}></i>
                                    </Tag>
                                </p>
                            )}
                            {/* {!value?.project_product_group_step_table_flow_finalized_at && ( */}
                            {value?.steps[0].chat_message_fk_situation_chat_id != 3 && (
                                <p
                                    style={{
                                        fontSize: 13,
                                        color: value.steps[0]?.delay_date?.color ? value.steps[0]?.delay_date?.color : '#e9e9e9'
                                    }}
                                >
                                    {value.steps[0].delay_date?.message}
                                </p>
                            )}
                        </div>
                    </div>
                </motion.div>
            )
        });
    }

    render(): ReactNode {
        return (
            <>

                <div
                    style={
                        {

                            margin: 'auto',
                            width: '100%',
                        }
                    }
                >
                    <div
                        style={
                            {
                                display: 'flex',
                                gap: 15,
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }
                        }
                    >
                        {!this.state.loading ? (
                            <>
                                {this.state.data.length === 0 ? (
                                    <div
                                        style={
                                            {
                                                display: 'flex',
                                                height: '50vh',
                                                justifyContent: 'center',
                                                alignItems: "center",
                                                gap: 5,
                                                flexWrap: 'wrap',
                                                textAlign: "center",
                                                background: '#e9e9e9',
                                                padding: 10,
                                                // borderRadius: 20,
                                                boxShadow: '20px 20px 10px 5px rgba(0, 0, 0, 0.2)',
                                                color: '#fff'
                                            }
                                        }
                                    >
                                        <h1>
                                            Sem Chats para carregar
                                        </h1>
                                        <i className="fas fa-comment-slash fa-5x"></i>
                                    </div>
                                ) : (
                                    <>
                                        {this.renderChats()}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {this.TwentyTimesParagraphs()}
                            </>
                        )}
                    </div>

                    <ConfirmModal
                        loading={this.state.loading}
                        onCancel={() => {
                            this.setState({
                                showDialogModal: false,
                            })
                        }}
                        funcaoclose={() => {
                            this.setState({
                                showDialogModal: false,
                            })
                        }}
                        body={this.state.modalDialogText}
                        content=""
                        // cancelColor={"red"}
                        showConfirm={true}
                        confirmColor="green"
                        cancelText={"Cancelar"}
                        onConfirm={() => this.initAppointment(this.state.dialogIndex, this.state.dialogParams)}
                        onCloseButton={true}
                        title={<>
                            <Icon
                                icon="remind"
                                style={{
                                    color: '#ffb300',
                                    fontSize: 18,
                                    marginTop: 6
                                }}
                            />
                            &nbsp;Atenção!
                        </>}
                        show={this.state.showDialogModal}
                    />
                </div>
            </>
        )
    }
}