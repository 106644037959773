import React from "react";
import { Placeholder, Container, Content, Drawer, Button, Notification } from "rsuite";
import axios from "../../../../../utilities/axios";
import { HeaderComponent } from "../components/HeaderComponent";
import { ChatComponentList } from "../components/ChatComponentList";
import _ from "lodash";
import { ChatPage } from "./ChatPage";

interface ChatListProps {
    nextRouter: ({ route }: { route: string, paramsRoute: Record<string, any> }) => void;
    previousRoute: ({ route, dataParams }: { route: string, dataParams: Record<string, any> }) => void;
    paramsRoute: Record<string, any>;
}


export class ChatList extends React.Component<any, any> {
    state: any = {
        data: [],
        projectProductData: null,
        projectData: null,
        isDrawerOpen: false,
        step: null
    }

    componentDidMount(): void {
        let projectId = this.props?.match?.params?.projectId;
        let projectProductId = this.props?.match?.params?.projectProductId;
        projectId = projectId ? projectId : this.props.projectId;
        projectProductId = this.props.projectProductId ? this.props.projectProductId : projectProductId;

        axios
            .get(`/api/v1/project_products/${projectProductId}`)
            .then((response) => {
                this.setState({ projectProductData: response.data.item });
                if (response.data?.redirect || _.isEmpty(response.data.item)) {
                    this.props.history.push(`/dashboard/project/chats/${projectId}`);
                    Notification.warning({
                        title: "Usuário sem permissão de acesso!"
                    });
                }

                this.handleProjectProductItem(response.data.item);
            });
    }

    handleProjectProductItem = (data: any) => {
        const baseURL = "/dashboard/project/chats";

        // console.log("handleProjectProductItem", data)
        if (this.props.nextRouter) {
            this.props.nextRouter(
                {
                    route: 'chatList',
                    paramsRoute: {
                        project_product_id: data.project_product_id,
                        project_product_fk_product_id: data.project_product_fk_product_id,
                        project_id: this.props?.paramsRoute?.project_id ? this.props?.paramsRoute?.project_id : this.props.projectId,
                        product_id: data.product_id,
                        pathUrl: [
                            {
                                labelCrud: 'Projeto',
                                labelValue: data.projects.project_code + " - " + data.projects.project_description + " - " + data.projects.person_name,
                                link: `${baseURL}/${data.projects.project_id}`,
                                value: 0
                            },
                            // {
                            //     labelCrud: 'Itens',
                            //     labelValue: data.products.product_name,
                            //     link: `${baseURL}/${data.projects.project_id}/${data.project_product_id}`,
                            //     value: 1
                            // }
                        ],
                        data
                    }
                }
            )
        }
    }

    selectItem = ({ data }: { data: Record<string, any> }) => {
        this.setState({ isDrawerOpen: true, step: data.steps[0] });
        // console.log("adasdasdas", data)
        if (this.props.match == null || this.props.match.params === undefined) {
            return
        }
        let { projectId, projectProductId } = this.props.match.params;
        projectId = projectId ? projectId : this.props.projectId;
        projectProductId = this.props.projectProductId ? this.props.projectProductId : projectProductId;
        if (data.steps[0].chat_message_fk_ppgstf_id) {
            this.props.history.push(`/dashboard/project/chats/${projectId}/${projectProductId}/${data.steps[0].chat_message_fk_ppgstf_id}`);
        } else {
            this.props.history.push(`/dashboard/project/chats/${projectId}/${projectProductId}`);
        }

        // this.props.nextRouter(
        //     {
        //         route: 'chatPage',
        //         paramsRoute: {
        //             step_item: {
        //                 step_color: data.steps[0].step_color,
        //                 step_created_at: data.steps[0].step_created_at,
        //                 step_created_by: data.steps[0].step_created_by,
        //                 step_fk_company_id: data.steps[0].step_fk_company_id,
        //                 step_id: data.steps[0].step_id,
        //                 step_name: data.steps[0].step_name,
        //                 step_updated_at: data.steps[0].step_updated_at,
        //             },
        //             step_id: data.steps[0].step_id,
        //             project_product_id: this.props.paramsRoute.project_product_id,
        //             project_product_fk_product_id: this.props.paramsRoute.project_product_fk_product_id,
        //             project_id: this.props.paramsRoute.project_id,
        //             product_id: this.props.paramsRoute.product_id,
        //             pathUrl: [
        //                 ...this.props.paramsRoute.pathUrl,
        //                 {
        //                     labelCrud: 'Chats',
        //                     labelValue: data.steps[0].step_name,
        //                     value: 3
        //                 }
        //             ],
        //             data
        //         }
        //     }
        // )

        // console.log("tatatat", data)
    }

    render(): React.ReactNode {
        const { projectProductData } = this.state;
        let projectId = this.props?.match?.params?.projectId;
        let projectProductId = this.props?.match?.params?.projectProductId;
        projectId = projectId ? projectId : this.props.projectId;
        projectProductId = this.props.projectProductId ? this.props.projectProductId : projectProductId;

        const project_product_fk_product_id = this.state.projectProductData?.project_product_fk_product_id;

        if (project_product_fk_product_id) {
            return (
                <>
                    <div
                        style={
                            {
                                display: 'flex',
                                flexDirection: "column",
                                gap: 20
                            }
                        }
                    >
                        {this.props.disableHeader ? (
                            <></>
                        ) : (
                            <HeaderComponent
                                {...this.props}
                                dataParams={this.props.paramsRoute}
                                previousRoute={`chats/${projectId}`}
                                showInfoButton
                            />
                        )}
                        <ChatComponentList
                            {...this.props}
                            params={{
                                project_product_fk_product_id,
                                project_product_id: projectProductId
                            }}
                            selectItem={this.selectItem}
                            typeCall="webUser"
                        />

                        <Drawer
                            placement="right"
                            size="md"
                            backdrop
                            autoFocus
                            show={false}
                            onHide={() => { this.props.history.push(`/dashboard/project/chats/${projectId}/${projectProductId}`) }}
                        >
                            <Drawer.Header>
                                <Drawer.Title><i className="far fa-comments"></i> {projectProductData?.projects?.project_code} - {projectProductData?.projects?.project_description} - {projectProductData?.projects.person_name} - {this.state.step?.step_name}</Drawer.Title>
                            </Drawer.Header>
                            <Drawer.Body>
                                <ChatPage
                                    {...this.props}
                                    disableHeader
                                    chat_message_fk_ppgstf_id={this.state.step?.chat_message_fk_ppgstf_id}
                                    projectId={projectId}
                                    projectProductId={projectProductId}
                                    nextRouter={this.props.nextRouter}
                                    previousRoute={this.props.previousRoute}
                                    paramsRoute={this.props.paramsRoute}
                                />
                            </Drawer.Body>
                            <Drawer.Footer>

                            </Drawer.Footer>
                        </Drawer>
                    </div>
                </>
            )
        } else {
            return <Placeholder.Paragraph rows={12} active />;
        }
    }
}